import styles from './index.module.css';

import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import Label from 'Core/components/display/Label';
import PropTypes from 'prop-types';
import NumberLabel from 'Core/components/display/NumberLabel';

class PatientStatistics extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'PatientStatistics',
		});
	}

	render() {
		const {className} = this.props;
		/** @type {PatientRecordDataObject} */
		const data = this.props.data;

		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${className}`}>
				<div className={`section-content ${styles['grid']}`}>
					<div className={`${styles['gridItem']} ${styles['group']}`}>
						<div>
							<Label 
								element="div"
								elementProps={{className: styles['groupTitle']}}
								icon="ui-calendar"
								iconSymbolPrefix="icofont-"
								content={this.t('treatmentsTitle')} 
							/>

							<div className={styles['groupValue']}>
								<Label element="label" icon="sign-in" content={this.t('payedTreatmentsLabel')}/>
								<span className={styles['dots']}/>
								<NumberLabel element="span" number={data?.payedTreatments} defaultOutput="—"/>
							</div>

							<div className={styles['groupValue']}>
								<Label element="label" icon="sign-out" content={this.t('finishedTreatmentsLabel')}/>
								<span className={styles['dots']}/>
								<NumberLabel element="span" number={data?.finishedTreatments} defaultOutput="—"/>
							</div>

							<div
								className={
									`${styles['groupValue']} ` +
									`${data?.remainingTreatments < 0 ? 'error-color' : ''} ` +
									`${data?.remainingTreatments > 0 ? 'success-color' : ''}`
								}
							>
								<Label
									element="label"
									icon={(
										data?.remainingTreatments < 0 ? 'exclamation-circle' :
										data?.remainingTreatments > 0 ? 'check-circle' :
										'minus-circle'
									)}
									content={this.t('remainingTreatmentsLabel')}
								/>
								<span className={styles['dots']}/>
								<NumberLabel element="span" number={data?.remainingTreatments} defaultOutput="—"/>
							</div>
						</div>
					</div>
					<div className={`${styles['gridItem']} ${styles['group']}`}>
						<div>
							<Label
								element="div"
								elementProps={{className: styles['groupTitle']}}
								icon="money"
								iconSymbolPrefix="icofont-"
								content={this.t('monetizationTitle')}
							/>

							<div className={styles['groupValue']}>
								<Label
									element="label"
									icon="wallet"
									iconSymbolPrefix="icofont-"
									content={this.t('totalPayedLabel')}
								/>
								<span className={styles['dots']}/>
								<NumberLabel
									element="span"
									number={data?.totalPayed}
									defaultOutput="—"
									format="0,0.00"
								/>
							</div>
							<div 
								className={
									`${styles['groupValue']} ` +
									(
										data?.totalDebt === 0 ? 'success-color' :
										data?.totalDebt > 0 ? 'error-color' : 
										''
									)
								}
							>
								<Label
									element="label"
									icon={(data?.totalDebt > 0 ? 'exclamation-circle' : 'minus-circle')}
									iconSymbolPrefix="icofont-"
									content={this.t('totalDebtLabel')}
								/>
								<span className={styles['dots']}/>
								<NumberLabel
									element="span"
									number={data?.totalDebt}
									defaultOutput="—"
									format="0,0.00"
								/>
							</div>
						</div>
					</div>

					{/*<div className={styles['gridItem']}>
						<Label element="label" icon="sign-in" content={this.t('payedTreatmentsLabel')} />
						<NumberLabel element="span" number={data?.payedTreatments} defaultOutput="—" />
					</div>
					<div className={styles['gridItem']}>
						<Label element="label" icon="sign-out" content={this.t('finishedTreatmentsLabel')} />
						<NumberLabel element="span" number={data?.finishedTreatments} defaultOutput="—" />
					</div>
					<div 
						className={
							`${styles['gridItem']} ` +
							`${data?.remainingTreatments < 0 ? styles['error'] : ''} ` +
							`${data?.remainingTreatments > 0 ? styles['success'] : ''}`
						}
					>
						<Label 
							element="label"
							icon={(
								data?.remainingTreatments < 0 ? 'exclamation-circle' :
								data?.remainingTreatments > 0 ? 'check-circle' :
								'minus-circle'
							)}
							content={this.t('remainingTreatmentsLabel')} 
						/>
						<NumberLabel element="span" number={data?.remainingTreatments} defaultOutput="—"/>
					</div>
					<div className={styles['gridItem']}>
						<Label element="label" icon="money" iconSymbolPrefix="icofont-" content={this.t('totalPayedLabel')} />
						<NumberLabel 
							element="span" 
							number={data?.totalPayed} 
							defaultOutput="—" 
							format="0,0.00"
						/>
					</div>
					<div className={styles['gridItem']}>
						<Label element="label" icon="money" iconSymbolPrefix="icofont-" content={this.t('totalDebtLabel')}/>
						<NumberLabel
							element="span"
							number={data?.totalDebt}
							defaultOutput="—"
							format="0,0.00"
						/>
					</div>*/}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientStatistics.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	// @type {PatientRecordDataObject}
	data: PropTypes.object,
};

/**
 * Define component default values for own props
 */
PatientStatistics.defaultProps = {
	id: '',
	className: '',
};

export default PatientStatistics;