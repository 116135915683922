import {cloneDeep, get} from 'lodash';
import {getNumber, getObject, getString} from 'Core/helpers/data';
import {PatientRecordDataObject} from 'Components/advanced/PatientRecord/dataObjects';
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientRecordDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientRecordDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'ownerId'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'address'),
		getObject(result, 'city', null),
		getNumber(result, 'numberOfPayedAppointments', null),
		getNumber(result, 'numberOfUsedAppointments', null),
		getNumber(result, 'numberOfRemainingAppointments', null),
		getNumber(result, 'payedAmount', null),
		getString(result, 'recommendationId'),
		getString(result, 'recommendationName'),
		getString(result, 'anamnesis'),
		getNumber(result, 'totalDebt', null),
	);

	return result;
}
