import {PAYMENT_TYPE, PAYMENT_TYPES} from "Components/dialogs/PaymentDialog/const";

/**
 * Cleanup amount values based no payment type
 * @description Depending on payment type only certain amount values need to be populated. On the hand, some amount
 * values must not be populated (should be 0 or null) for certain payment types and this function clears those values.
 *
 * @param {PaymentType} type - Payment type.
 * @param {number} amount - Amount paid.
 * @param {?number} debtAmount - Amount of debt for this payment.
 * @param {?number} returnedDebtAmount - Part of the 'amount' to be used as debt payment.
 * @return {{amount, returnedDebtAmount, debtAmount}}
 * @throws {Error} Throws an error if type is not specified or is not supported.
 */
export const cleanupPaymentAmountsForType = (type, amount, debtAmount, returnedDebtAmount) => {
	if (!type || !PAYMENT_TYPES.includes(type)) {
		throw new Error(`Payment type "${type}" is not supported while trying to cleanup amount values!`);
	}
	
	switch (type) {
		case PAYMENT_TYPE.FULL_AMOUNT:
			// Clear 'debtAmount' since payment is made in full and there is no depth for this payment.
			// Clear 'returnedDebtAmount' because this type is not used for paying debts (FULL_AMOUNT_WITH_DEBT_PAYMENT 
			// and PARTIAL_AMOUNT_WITH_DEBT_PAYMENT should be used for that).
			return {amount, debtAmount: null, returnedDebtAmount: null};
			
		case PAYMENT_TYPE.PARTIAL_AMOUNT:
			// Clear 'returnedDebtAmount' because this type is not used for paying debts (FULL_AMOUNT_WITH_DEBT_PAYMENT 
			// and PARTIAL_AMOUNT_WITH_DEBT_PAYMENT should be used for that). 
			return {amount, debtAmount, returnedDebtAmount: null};
			
		case PAYMENT_TYPE.FULL_AMOUNT_WITH_DEBT_PAYMENT:
			// Clear 'debtAmount' since payment is made in full and there is no depth for this payment.
			return {amount, debtAmount: null, returnedDebtAmount};
			
		case PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT:
			// Nothing is cleared because there is a new debt for this payment and old debt is also being paid.
			return {amount, debtAmount, returnedDebtAmount};
			
		// no default
	}
}