import "./index.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import DataValueValidation, {VALIDATION_FIELD_TYPE, ValidationConstraintObject} from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getNumber, getString, isset} from 'Core/helpers/data';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DateInput from 'Core/components/input/DateInput';
import {PaymentDataObject} from "DataObjects/payment";
import NumberInput from "Core/components/input/NumberInput";
import TextareaInput from "Core/components/input/TextareaInput";
import OfficeLocationSelectInput from "Components/input/OfficeLocationSelectInput";
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import {PAYMENT_TYPE, PAYMENT_TYPES} from "Components/dialogs/PaymentDialog/const";
import SelectInput from "Core/components/input/SelectInput";
import Label from "Core/components/display/Label";
import NumberLabel from "Core/components/display/NumberLabel";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({...actions, loadPatientTherapyAction, loadPatientRecordAction});

/**
 * Defines translation path fpr this component
 * @type {string}
 */
const translationPath = 'PaymentDialog';

class PaymentDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: new PaymentDataObject(null, props.patientId, props.therapyId, new Date()),
			type: PAYMENT_TYPE.FULL_AMOUNT,
		}, {
			translationPath: translationPath,
			enableLoadOnDataPropChange: true,
			disableLoad: true,
		});
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const {refund} = this.props;
		const {type} = this.state;
		const validation = new DataValueValidation();
		/** @type {PaymentDataObject} */
		const dataToValidate = this.getData();

		validation.addRule('date', 'required', 'date');
		validation.addConstraint('note', new ValidationConstraintObject('max', 255, VALIDATION_FIELD_TYPE.TEXT));
		if (!refund) {
			if ([PAYMENT_TYPE.FULL_AMOUNT_WITH_DEBT_PAYMENT,PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT].includes(type)){
				validation.addRule('returnedDebtAmount', 'required', 'number');
				validation.addConstraint(
					'returnedDebtAmount', new ValidationConstraintObject('min', 0.01, VALIDATION_FIELD_TYPE.NUMBER)
				);
			}
			if ([PAYMENT_TYPE.PARTIAL_AMOUNT, PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT].includes(type)) {
				validation.addRule('debtAmount', 'required', 'number');
				validation.addConstraint(
					'debtAmount', new ValidationConstraintObject('min', 0.01, VALIDATION_FIELD_TYPE.NUMBER)
				);
			}
		}

		// Run validation
		const validationErrors = validation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			refund, patientId, therapyId, dialogGUIID, createPaymentAction, addSuccessMessageAction, 
			loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;
		const {type} = this.state;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const payment = await this.executeAbortableAction(createPaymentAction, type, data, refund);

			if (isset(payment)) {
				// Show success message
				if (refund) addSuccessMessageAction(this.t('refund_success_msg'));
				else addSuccessMessageAction(this.t('create_success_msg'));

				// Reload patient record to update global statistics
				await this.executeAbortableAction(loadPatientRecordAction, patientId);
				// Reload patient therapy to update statistics
				if (!!therapyId && therapyId !== '*') {
					await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
				}
				
				this.close();

				// Trigger component's onSave event if payment was saved successfully
				executeComponentCallback(this.props.onSave, payment, dialogGUIID);
			}
		}
	}
	
	render() {
		const {therapyId, refund} = this.props;
		const {type} = this.state;
		/** @type {PaymentDataObject} */
		const data = this.getData();
		const debtWriteOffAmount = getNumber(data, 'returnedDebtAmount') - getNumber(data, 'amount');

		return this.renderDialog(
			this.renderTitle(this.t(
				!!therapyId ?
					(refund ? 'title_refund_therapy' : 'title_create_therapy') :
					(refund ? 'title_refund' : 'title_create')
			)),
			(
				<FormWrapper className="dialog-form">
					<FormField
						required={true}
						label={this.t('dateLabel')}
						errorMessages={this.getValidationErrors('date')}
					>
						<DateInput
							preventTab={true}
							value={data?.date || null}
							onChange={v => this.handleValueChange('date', v)}
						/>
					</FormField>

					{!refund ?
						<FormField
							required={true}
							label={this.t('typeLabel')}
						>
							<SelectInput
								className="payment-type"
								isClearable={false}
								value={type}
								options={PAYMENT_TYPES.map(t => ({label: this.tt(t, 'type'), value: t}))}
								onChange={type => this.setState({type}).then(() => this.clearValidationErrors())}
							/>
						</FormField>
						: null
					}
					
					<FormField
						label={this.t('appointmentsNumberLabel')}
						errorMessages={this.getValidationErrors('appointmentsNumber')}
					>
						<NumberInput
							intOnly={true}
							value={getNumber(data, 'appointmentsNumber', 1)}
							onChange={v => this.handleValueChange('appointmentsNumber', v)}
						/>
					</FormField>
					
					<FormField
						label={this.t('amountLabel')}
						errorMessages={this.getValidationErrors('amount')}
					>
						<NumberInput
							value={getNumber(data, 'amount', null, true)}
							onChange={v => this.handleValueChange('amount', v)}
						/>
					</FormField>

					{!refund ?
						<>
							{[
								PAYMENT_TYPE.FULL_AMOUNT_WITH_DEBT_PAYMENT,
								PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT
							].includes(type) ?
								<FormField
									required={true}
									label={
										type === PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT ?
											this.t('returnedOldDebtAmountLabel') :
											this.t('returnedDebtAmountLabel')
									}
									errorMessages={this.getValidationErrors('returnedDebtAmount')}
									infoMessages={debtWriteOffAmount > 0 ?
										[<>
											<Label content={this.t('debtWriteOffAmountLabel')} />: <NumberLabel 
											number={debtWriteOffAmount} />
										</>] 
										:
										[this.t('debtWriteOffInfoLabel')]
									}
								>
									<NumberInput
										min={0}
										value={getNumber(data, 'returnedDebtAmount', null, true)}
										onChange={v => this.handleValueChange('returnedDebtAmount', v)}
									/>
								</FormField>
								: null
							}

							{[PAYMENT_TYPE.PARTIAL_AMOUNT, PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT].includes(type) ?
								<FormField
									required={true}
									label={
										type === PAYMENT_TYPE.PARTIAL_AMOUNT_WITH_DEBT_PAYMENT ?
											this.t('newDebtAmountLabel') :
											this.t('debtAmountLabel')
									}
									errorMessages={this.getValidationErrors('debtAmount')}
								>
									<NumberInput
										min={0}
										value={getNumber(data, 'debtAmount', null, true)}
										onChange={v => this.handleValueChange('debtAmount', v)}
									/>
								</FormField>
								: null
							}
						</>
						: null
					}

					<FormField
						label={this.t('officeLocationIdLabel')}
						errorMessages={this.getValidationErrors('officeLocationId')}
					>
						<OfficeLocationSelectInput
							isClearable={true}
							value={data?.officeLocationId || null}
							onChange={v => this.handleValueChange('officeLocationId', v)}
						/>
					</FormField>
					
					<FormField
						label={this.t('noteLabel')}
						className="multiline-form-field"
						errorMessages={this.getValidationErrors('note')}
					>
						<TextareaInput
							maxLength={255}
							rows={3}
							name="note"
							value={getString(data, 'note')}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			),
			{
				createLabel: refund ? this.t('refund_btn') : this.t('create_btn'), 
				createIcon: 'check'
			}
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PaymentDialog.propTypes = {
	// ID of the patient
	id: PropTypes.string,
	// ID of the patient
	patientId: PropTypes.string.isRequired,
	// ID of the therapy
	therapyId: PropTypes.string,
	// Flag that specifies if this is a refund dialog
	refund: PropTypes.bool,

	// Event triggered if payment was saved successfully and dialog has already been closed
	// @param {PaymentDataObject} payment, 
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PaymentDialog.defaultProps = {
	isNew: true,
};

export {translationPath as paymentDialogTranslationPath};
export default connect(null, allActions)(PaymentDialog);