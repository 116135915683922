import {cloneDeep, get} from "lodash";
import {getNumber, getString} from 'Core/helpers/data';
import {PaymentsListItemDataObject} from "DataObjects/payment";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PaymentsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PaymentsListItemDataObject(
		getString(result, 'id'),
		getDate(get(result, 'paymentDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getNumber(result, 'appointmentsNumber'),
		getNumber(result, 'amount'),
		getNumber(result, 'debtAmount'),
		getNumber(result, 'returnedDebtAmount'),
		getString(result, 'note'),
		getString(result, 'therapyPatientId'),
		getString(result, 'therapyPatientFirstName'),
		getString(result, 'therapyPatientLastName'),
		getString(result, 'officeLocationName'),
	);

	return result;
}

// @note There is no output function since list is read only.