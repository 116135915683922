/** @typedef {string} PaymentType */
export const PAYMENT_TYPE_FULL_AMOUNT = 'FULL_AMOUNT';
export const PAYMENT_TYPE_PARTIAL_AMOUNT = 'PARTIAL_AMOUNT';
export const PAYMENT_TYPE_FULL_AMOUNT_WITH_DEBT_PAYMENT = 'FULL_AMOUNT_WITH_DEBT_PAYMENT';
export const PAYMENT_TYPE_PARTIAL_AMOUNT_WITH_DEBT_PAYMENT = 'PARTIAL_AMOUNT_WITH_DEBT_PAYMENT';
/** @enum {PaymentType} */
export const PAYMENT_TYPE = {
	FULL_AMOUNT: PAYMENT_TYPE_FULL_AMOUNT,
	PARTIAL_AMOUNT: PAYMENT_TYPE_PARTIAL_AMOUNT,
	FULL_AMOUNT_WITH_DEBT_PAYMENT: PAYMENT_TYPE_FULL_AMOUNT_WITH_DEBT_PAYMENT,
	PARTIAL_AMOUNT_WITH_DEBT_PAYMENT: PAYMENT_TYPE_PARTIAL_AMOUNT_WITH_DEBT_PAYMENT,
};
/** @type {PaymentType[]} */
export const PAYMENT_TYPES = [
	PAYMENT_TYPE_FULL_AMOUNT,
	PAYMENT_TYPE_PARTIAL_AMOUNT,
	PAYMENT_TYPE_FULL_AMOUNT_WITH_DEBT_PAYMENT,
	PAYMENT_TYPE_PARTIAL_AMOUNT_WITH_DEBT_PAYMENT,
];