import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction} from 'Core/store/actions/io';
import {getString, isset} from 'Core/helpers/data';
import * as paymentDataMap from "DataMap/payment";
import {get} from 'lodash';
import {cleanupPaymentAmountsForType} from "Components/dialogs/PaymentDialog/helpers";
import {showErrorMessage} from "Core/helpers/message";

/**
 * Create a payment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PaymentType} type - Type of payment to perform.
 * @param {PaymentDataObject} payment
 * @param {boolean} [refund] - Flag that specifies if output should be mapped for refund.
 * @return {function(*): Promise<PaymentDataObject|undefined>}
 */
export const createPaymentAction = (abortCallback, type, payment, refund) => dispatch => {
	const loading = showDialogLoading('create-payment-dialog');

	// Cleanup amount values based no payment type
	// @description Depending on payment type only certain amount values need to be populated. On the hand, some amount
	// values must not be populated (should be 0 or null) for certain payment types and this function clears those 
	// values.
	let paymentToUse;
	try {
		paymentToUse = {
			...payment,
			...cleanupPaymentAmountsForType(type, payment.amount, payment.debtAmount, payment.returnedDebtAmount)
		};
	} catch (e) {
		// @note We need to make sure that we don't create a payment with invalid amount values (amount values that were 
		// not properly cleaned up) so if 'cleanupPaymentAmountsForType' function throws an error we should not call IO.
		// Error message is not translated because this error should not happen unless someone is tampering with the 
		// JavaScript code.
		showErrorMessage(getString(e, 'message'));
		return Promise.resolve(undefined);
	}
	
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/payment/create',
		{
			id: null,
			data: paymentDataMap.output(paymentToUse, refund),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? paymentDataMap.input(get(res, 'data')) : undefined);
}
