export class AppointmentVisitDataObjects {
	/**
	 * @param {AppointmentUpdateDataObject} appointment - Appointment data used for creating a visit.
	 * @param {?string} [note=null] - Visit note.
	 * @param {number} [paymentAppointmentsNumber=1] - Number of appointments paid on visit.
	 * @param {?number} [paymentAmount=null] - Amount paid on visit.
	 * @param {?number} [paymentDebtAmount=null] - Amount of debt for this visit.
	 * @param {?number} [paymentReturnedDebtAmount=null] - Part of the 'paymentAmount' to be used as debt payment.
	 * @param {string} [paymentNote=''] - Payment note.
	 */
	constructor(
		appointment, note = null, paymentAppointmentsNumber = 1, paymentAmount = null, paymentDebtAmount = null,
		paymentReturnedDebtAmount = null, paymentNote = ''
	) {
		this.appointment = appointment;
		this.note = note;
		this.paymentAppointmentsNumber = paymentAppointmentsNumber;
		this.paymentAmount = paymentAmount;
		this.paymentDebtAmount = paymentDebtAmount;
		this.paymentReturnedDebtAmount = paymentReturnedDebtAmount;
		this.paymentNote = paymentNote;
	}
}