import {cloneDeep, get} from 'lodash';
import {getArray, getBool, getNumber, getString, resetObjectValues} from 'Core/helpers/data';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';
import {PatientTherapyDataObject} from "DataObjects/patientTherapy";
import * as physicianDataMap from 'DataMap/physician';
import * as prescribedPatientTherapyDataMap from 'DataMap/patientTherapy/prescribed';
import * as diagnosisDataMap from 'DataMap/diagnosis';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientTherapyDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientTherapyDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'customerId', null),
		getDate(get(result, 'therapyDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getString(result, 'medicalRecordCategory.uniqueID', null, true),
		getString(result, 'medicalRecordCategory.categoryName', null),
		getNumber(result, 'medicalRecordNumber', null),
		getString(result, 'prescribedTherapiesCount'),
		getString(result, 'symptoms'),
		getString(result, 'diagnosisCodeBookId', null, true),
		getString(result, 'diagnosis'),
		get(result, 'diagnosisData') ? diagnosisDataMap.input(get(result, 'diagnosisData')) : null,
		get(result, 'physicianBasicData') ? physicianDataMap.input(get(result, 'physicianBasicData')) : null,
		getBool(result, 'customPhysicianInput'),
		getString(result, 'sentByPhysician', null, true),
		getArray(result, 'prescribedTherapies').map(i => prescribedPatientTherapyDataMap.input(i)),
		getNumber(result, 'numberOfPayedAppointments', null),
		getNumber(result, 'numberOfUsedAppointments', null),
		getNumber(result, 'numberOfRemainingAppointments', null),
		getNumber(result, 'payedAmount', null),
		getNumber(result, 'totalDebt', null),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {PatientTherapyDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueID: inputData.id,
		customerId: inputData.customerId,
		physicianBasicData: (
			!inputData.customPhysician && !!inputData.physician ? physicianDataMap.output(inputData.physician) : null
		),
		diagnosisCodeBookId: inputData.diagnosisData?.id || null,
		symptoms: inputData.symptoms,
		diagnosis: inputData.diagnosis,
		prescribedTherapiesCount: inputData.prescribedTherapiesCount,
		medicalRecordNumber: inputData.medicalRecordNumber,
		medicalRecordCategoryId: inputData.medicalRecordCategoryId,
		therapyDate: getDateString(inputData.therapyDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		prescribedTherapies: getArray(inputData, 'prescribedTherapies').map(i=>prescribedPatientTherapyDataMap.output(i)),
		customPhysicianInput: inputData.customPhysician,
		sentByPhysician: inputData.customPhysician ? inputData.customPhysicianName : null,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}