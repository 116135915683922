export class PatientTherapyDataObject {
	/**
	 * @param {?string} [id=null]
	 * @param {string} [customerId='']
	 * @param {Date} [therapyDate]
	 * @param {?string} [medicalRecordCategoryId=null]
	 * @param {string} [medicalRecordCategoryName='']
	 * @param {?number} [medicalRecordNumber=null]
	 * @param {string} [prescribedTherapiesCount='1']
	 * @param {string} [symptoms='']
	 * @param {?string} [diagnosisId=null]
	 * @param {string} [diagnosis='']
	 * @param {DiagnosisDataObject} [diagnosisData=null]
	 * @param {?PhysicianDataObject} [physician=null]
	 * @param {boolean} [customPhysician=false]
	 * @param {?string} [customPhysicianName=null]
	 * @param {PatientPrescribedTherapyDataObject[]} [prescribedTherapies=[]]
	 * @param {?number} [payedTreatments=null]
	 * @param {?number} [finishedTreatments=null]
	 * @param {?number} [remainingTreatments=null]
	 * @param {?number} [totalPayed=null]
	 * @param {?number} [totalDebt=null]
	 */
	constructor(
		id = null, customerId = '', therapyDate = new Date(), medicalRecordCategoryId = null, 
		medicalRecordCategoryName = '', medicalRecordNumber = null, prescribedTherapiesCount = '1', symptoms = '', 
		diagnosisId = null, diagnosis = '', diagnosisData = null, physician = null, customPhysician = false, 
		customPhysicianName = null, prescribedTherapies = [], payedTreatments = null, finishedTreatments = null, 
		remainingTreatments = null, totalPayed = null, totalDebt = null,
	) {
		this.id = id;
		this.customerId = customerId;
		this.therapyDate = therapyDate;
		this.medicalRecordCategoryId = medicalRecordCategoryId;
		this.medicalRecordCategoryName = medicalRecordCategoryName;
		this.medicalRecordNumber = medicalRecordNumber;
		this.prescribedTherapiesCount = prescribedTherapiesCount;
		this.symptoms = symptoms;
		this.diagnosisId = diagnosisId;
		this.diagnosis = diagnosis;
		this.diagnosisData = diagnosisData;
		this.physician = physician;
		this.customPhysician = customPhysician;
		this.customPhysicianName = customPhysicianName;
		this.prescribedTherapies = prescribedTherapies;
		this.payedTreatments = payedTreatments;
		this.finishedTreatments = finishedTreatments;
		this.remainingTreatments = remainingTreatments;
		this.totalPayed = totalPayed;
		this.totalDebt = totalDebt;
	}
}

export class PatientPrescribedTherapyDataObject {
	/**
	 * @param {?string} [id=null]
	 * @param {string} [prescribedTherapyType='']
	 * @param {string} [prescribedTherapyTypeId='']
	 * @param {string} [prescribedTherapySubtype='']
	 * @param {string} [prescribedTherapySubtypeId='']
	 * @param {string} [prescribedTherapySubSubtype='']
	 * @param {string} [prescribedTherapySubSubtypeId='']
	 * @param {string} [duration='']
	 * @param {string} [additionalInfo='']
	 * @param {boolean} [active=true]
	 */
	constructor(
		id = null, prescribedTherapyType = '', prescribedTherapyTypeId = '', prescribedTherapySubtype = '', 
		prescribedTherapySubtypeId = '', prescribedTherapySubSubtype = '', prescribedTherapySubSubtypeId = '', 
		duration = '', additionalInfo = '', active = true
	) {
		this.id = id;
		this.prescribedTherapyType = prescribedTherapyType;
		this.prescribedTherapyTypeId = prescribedTherapyTypeId;
		this.prescribedTherapySubtype = prescribedTherapySubtype;
		this.prescribedTherapySubtypeId = prescribedTherapySubtypeId;
		this.prescribedTherapySubSubtype = prescribedTherapySubSubtype;
		this.prescribedTherapySubSubtypeId = prescribedTherapySubSubtypeId;
		this.duration = duration;
		this.additionalInfo = additionalInfo;
		this.active = active;
	}
}